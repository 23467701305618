import React, { useCallback, useState } from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"
import NavBar from "../../components/NavBar/NavBar"
import { isSSR } from "../../components/Script/script"

import ScrollEmojiWindow from "./components/ScrollListWindow"
import ListAddInput from "./components/EmojiListCopyPaste"
import Popup from "./components/Popup"

import {
  Title,
  Dropdown,
  Icon,
  Button,
  ArrowDown,
} from "../../StyleComponents/styles"

const DropDownList = React.lazy(() => import("./components/DropDown"))
const Pagination = React.lazy(() =>
  import("../../components/Pagination/Pagination")
)

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  { name: "All 🤯 EMOJI", link: "/emoji-copy-and-paste/" },
]
const seeAlsoArray = [
  "/emoticons-copy-and-paste",
  "/font-generator",
  "/text-emoticons-face-generator",
  "/html-entity",
  "/text-art",
]

function EmojiNamesList(props) {
  const { currentPage, totalPages, pageItems, allPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === totalPages
  const prevPage =
    currentPage - 1 === 1
      ? "/emoji-copy-and-paste/"
      : `/emoji-copy-and-paste/${(currentPage - 1).toString()}/`
  const nextPage = `/emoji-copy-and-paste/${(currentPage + 1).toString()}/`

  // let listE = useRef("")
  let [addListEmoji, setaddListEmoji] = useState(() => {
    return typeof window !== "undefined"
      ? localStorage.getItem("Emoji") || ""
      : ""
  })
  let [emoji, setEmoji] = useState("")
  let [show, setShow] = useState(false)

  const mycopy = useCallback(e => {
    let emoji = e.target.value
    e.target.select()
    let copied = document.execCommand("copy")
    if (copied) {
      setEmoji(emoji)
      setShow(true)
    }
  }, [])

  function addTOlistFunction(e) {
    setaddListEmoji(addListEmoji + emoji)
    let btnElement = e.target.firstElementChild
    btnElement.style.display = "block"
    setTimeout(function () {
      btnElement.style.display = "none"
    }, 1500)
  }

  return (
    <Layout location={props.location}>
      <SEO
        title={`✂️ Copy And 📋 Paste Emoji 🤯 `}
        description={`Copy And Paste Emoji 🤯, beautiful Emoji Copy Paste wherever you like, just press on the emoji you won't copy.`}
        keywords={[
          `copy emoji, Copy Paste Emoji,emoji copy,emoji Symbols,emoji People and Fantasy,flag emojis for every country,emoji Travel & Places,emoji Activity and Sports,emoji Food & Drink,emoji Animals & Nature, Black Emojis, Dark Brown Emojis, Moderate Brown Emojis, Cream White Emojis, Pale Emojis,emoji smiley, emoji callery, nice emoji, ${new Date().getFullYear()} emoji.`,
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <section className="container">
        <Title id="listOfEmoji">
          <span role="img" aria-label="crazy-face">
            🤯
          </span>{" "}
          Emoji Copy And Paste
        </Title>
        <br />
        <Dropdown className="mb-4">
          <Button aria-haspopup="true" aria-controls="dropdown-menu">
            Emojis Category
            <Icon>
              <ArrowDown />
            </Icon>
          </Button>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <DropDownList list={allPages} />
            </React.Suspense>
          )}
        </Dropdown>
        <br />
        {show && (
          <Popup
            emoji={emoji}
            close={() => setShow(false)}
            addToList={addTOlistFunction}
          />
        )}
        <ListAddInput
          valueInput={addListEmoji}
          setValueInput={setaddListEmoji}
        />

        <br />
        <ScrollEmojiWindow pageItems={pageItems} mycopy={mycopy} />
        <br />
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              isFirst={isFirst}
              isLast={isLast}
              prevPage={prevPage}
              nextPage={nextPage}
              pagesLink="emoji-copy-and-paste"
            />
          </React.Suspense>
        )}
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </section>
    </Layout>
  )
}

export default EmojiNamesList
